/*Loader For Details css*/
.wrapper {
  margin: 30px;
  padding: 30px;
  background: #fff;
  width: 100%;
  // height: 640px;
  display: flex;
  flex-direction: column;
}

.wrapper-cell {
  display: flex;
  margin-bottom: 30px;
}

/* Animation */
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

/* Page Elements */
.image {
  height: 60px;
  width: 60px;
  // background: #f1efef;
  @extend .animated-background;
}

.text {
  margin-left: 20px;
}

.text-line {
  height: 10px;
  width: 70vw;
  // background: #f1efef;
  margin: 4px 0;
  @extend .animated-background;
}
